import React from 'react';
import { Container } from 'semantic-ui-react';

const resetPasswordConfirm = () => {
  return (
    <Container>
      <p>We've reset your password. Please check your email.</p>;
    </Container>
  );
};

export default resetPasswordConfirm;
